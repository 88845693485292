import React, { useEffect, useState } from 'react';

import Hero, { Main, RightSidebar } from 'renderer/components/templates/Hero';

import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';
import WindowsLogo from './windows-logo-2021.png';
import UbuntuLogo from './ubuntu-logo.png';
import MacosLogo from './macos-logo.png';

import Title2 from 'renderer/system/typography/Title2';
import Profile, { ProfileSize } from 'renderer/system/molecules/Profile';
import Section, { SectionMode } from 'renderer/system/misc/Section';
import Separator from 'renderer/system/atoms/Separator';
import Card, { CardModes } from 'renderer/system/molecules/Card';

import { useGetUserQuery } from './queries';

import yaml from 'yaml';

type FileEntry = {
  url: string;
  sha512: string;
  size: number;
  blockMapSize?: number;
};

type VersionInfo = {
  version: string;
  files: FileEntry[];
  path: string;
  sha512: string;
  releaseDate: string;
};

type Downloadables = {
  windows: VersionInfo | null;
  macos: VersionInfo | null;
  linux: VersionInfo | null;
};

const baseUrl = 'https://studio-updates.s3.us-west-2.amazonaws.com';

const latestYaml = {
  windows: baseUrl + '/latest.yml',
  macos: baseUrl + '/latest-mac.yml',
  linux: baseUrl + '/latest-linux.yml',
};

const DownloadManager: React.FC = () => {
  const [downloadables, setDownloadables] = useState<Downloadables | null>(null);

  const userQuery = useGetUserQuery();
  const user = userQuery.data?.auth?.user ?? null;

  useEffect(() => {
    // Fetch the latest download links
    const fetchDownloadables = async () => {
      const versions: Downloadables = {
        windows: null,
        macos: null,
        linux: null,
      };

      {
        const response = await fetch(latestYaml.windows);
        const data = await response.text();
        const parsed: VersionInfo = yaml.parse(data);
        versions.windows = parsed;
      }

      {
        const response = await fetch(latestYaml.macos);
        const data = await response.text();
        const parsed: VersionInfo = yaml.parse(data);
        versions.macos = parsed;
      }

      {
        const response = await fetch(latestYaml.linux);
        const data = await response.text();
        const parsed: VersionInfo = yaml.parse(data);
        versions.linux = parsed;
      }

      setDownloadables(versions);
    };

    fetchDownloadables();
  }, []);

  if (!user || userQuery.loading)
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Not logged in</Title2>
        </RightSidebar>
      </Hero>
    );

  return (
    <Hero
      backgroundImage={SkyImage}
      keystone={
        user && <Profile firstName={user.firstName} lastName={user.lastName} size={ProfileSize.MEDIUM} />
      }
    >
      <Main>
        <Focus>Ultimate Engine</Focus>
      </Main>
      <RightSidebar>
        <Section mode={SectionMode.Focus}>
          <Title2>Welcome, {user.firstName}</Title2>
        </Section>
        <Separator />
        <Section vertical={2}>
          <Card
            header="macOS (ARM)"
            mode={CardModes.HORIZONTAL}
            secondary={{
              label: 'Download',
              action: () => {
                window.open(baseUrl + '/' + downloadables?.macos?.files[2].url);
              },
            }}
            image={{
              source: MacosLogo,
              description: 'Mac (Apple-silicon) logo',
            }}
          />
        </Section>
        <Section vertical={2}>
          <Card
            header="macOS (x64)"
            mode={CardModes.HORIZONTAL}
            secondary={{
              label: 'Download',
              action: () => {
                window.open(baseUrl + '/' + downloadables?.macos?.files[3].url);
              },
            }}
            image={{
              source: MacosLogo,
              description: 'Mac (Intel) logo',
            }}
          />
        </Section>
        <Section vertical={2}>
          <Card
            header="Windows (x64)"
            mode={CardModes.HORIZONTAL}
            secondary={{
              label: 'Download',
              action: () => {
                window.open(baseUrl + '/' + downloadables?.windows?.files[0].url);
              },
            }}
            image={{
              source: WindowsLogo,
              description: 'Windows logo',
            }}
          />
        </Section>
        <Section vertical={2}>
          <Card
            header="Linux (x64)"
            mode={CardModes.HORIZONTAL}
            secondary={{
              label: 'Download',
              action: () => {
                window.open(baseUrl + '/' + downloadables?.linux?.files[0].url);
              },
            }}
            image={{
              source: UbuntuLogo,
              description: 'Ubuntu (linux) logo',
            }}
          />
        </Section>
      </RightSidebar>
    </Hero>
  );
};

export default DownloadManager;
