export enum NodeTypes {
  UNKNOWN = 'unknown',
  CODE = 'code',
  SCENE = 'scene',
  PROJECT = 'project',
  LOCKFILE = 'lockfile',
  IMAGE = 'image',
  RAW = 'raw',
  META = 'meta',
  COMPRESSED = 'compressed',
  ZIP = 'zip',
  MODEL = 'model',
  MODEL_LOD = 'model_lod',
  HDR = 'hdr',
  BINARY = 'binary',
  DIRECTORY = 'directory',
  PARTICLE = 'particle',
  AUDIO = 'audio',
  FONT = 'font',
  AUDIO_GRAPH = 'audio-graph',

  // Virtual Nodes
  MATERIAL = 'material',
  ANIMATION = 'animation',
  TERRAIN = 'terrain',
  ANIMATION_STATE_MACHINE = 'animation-state-machine',
  BEHAVIOR_TREE = 'behavior-tree',
  PLANNER = 'planner',
}

export type FilesystemNode =
  | {
      type: NodeTypes.TERRAIN;
      name: string;
      path: string;
      full: string;
      size: number;
      extension: string;
      isResource: boolean;
      maxX: number;
      maxY: number;
      width: number;
      height: number;
      tileCount: number;
    }
  | {
      type: NodeTypes;
      name: string;
      path: string;
      full: string;
      size: number;
      extension: string;
      isResource: boolean;
    };

export class Directory {
  public basedir: string;
  public basenode: Node;

  constructor(basedir: string) {
    this.basedir = basedir;
    this.basenode = Node.new();
  }

  public insert(node: FilesystemNode) {
    // TODO: This is a little untidy. We should probably be using `path` for this
    // but `path` is not available to the `renderer` bundle since it runs on
    // the browser. There must be a better solution for this, but this works for now.
    if (process.env.IS_WINDOWS) {
      const paths = node.path.split('\\');
      this.basenode.insert(paths, node);
    } else {
      const paths = node.path.split('/');
      this.basenode.insert(paths, node);
    }
  }
}

export class Node {
  public node!: FilesystemNode | null;
  public children!: { [key: string]: Node };

  static withNode(node: FilesystemNode): Node {
    const treeNode = new Node();
    treeNode.node = node;
    treeNode.children = {};
    return treeNode;
  }

  static new(): Node {
    const treeNode = new Node();
    treeNode.node = null;
    treeNode.children = {};
    return treeNode;
  }

  insert(paths: string[], node: FilesystemNode) {
    const [current, ...remaining] = paths;

    let child = this.children[current];

    if (!child) {
      child = Node.new();
      this.children[current] = child;
    }

    if (remaining.length <= 0) {
      child.node = node;
      return;
    }

    child.insert(remaining, node);
  }
}

export const normalizePath = (path: string): string => {
  return path.replaceAll('\\', '/');
};

export const getDirectory = (path: string): string => {
  return path.substring(0, path.lastIndexOf('/'));
};
