import { AnimationStateMachine, Particle, Prefab } from '../../../dist/pkg/studio';
import { LVL_VERSION, VFX_VERSION, Level } from './level';
import * as uuid from 'uuid';
//import { normalizePath } from 'common/filesystem';

export async function migrateLevel(lvl: any): Promise<Level> {
  // NOTE: whenever we add a new migration, we should make sure a default case is present.
  // For example, the first level files ever dont't have `animation_state`, so if we tried
  // to migrate those it would crash Studio. In summary, add `|| {}` or similar where needed.

  while (lvl.version !== LVL_VERSION) {
    switch (lvl.version) {
      case 'v0.0.7': {
        lvl.version = 'v0.0.8';
        Object.values<Prefab>(lvl.scene.prefabs).forEach((prefab) => {
          prefab.transform = { transform: prefab.transform as any };
          prefab.components
            .filter((component) => component['ParentComponent'])
            .forEach((component) => {
              component['ParentComponent'].parent_id = component['ParentComponent'].id;
              delete component['ParentComponent'].id;
            });
        });
        continue;
      }
      case 'v0.0.6': {
        lvl.version = 'v0.0.7';

        // We split SkyLightComponent.intensity to lighting_intensity and background_intensity

        Object.values<Prefab>(lvl.scene.prefabs)
          .flatMap((prefab) => prefab.components)
          .filter((component) => component['SkyLightComponent'])
          .forEach((component) => {
            const newComponent = component['SkyLightComponent'];
            if (Object.keys(newComponent)[0] === 'Dynamic') {
              const intensity = newComponent['Dynamic'].intensity;
              delete newComponent['Dynamic'].intensity;

              newComponent['Dynamic'].lighting_intensity = intensity;
              newComponent['Dynamic'].background_intensity = intensity;

              component['SkyLightComponent'] = newComponent;
            }
          });

        continue;
      }
      case 'v0.0.5': {
        // We changed PhysicsComponent > Heightfield > half_length to length
        lvl.version = 'v0.0.6';
        Object.values<Prefab>(lvl.scene.prefabs)
          .flatMap((prefab) => prefab.components)
          .filter((component) => component['PhysicsComponent'])
          .forEach((component) => {
            const newComponent = component['PhysicsComponent'];
            if (Object.keys(newComponent.joint.body.collider_type)[0] === 'Heightfield') {
              const half_length = newComponent.joint.body.collider_type['Heightfield'].half_length;
              newComponent.joint.body.collider_type['Heightfield'].length = half_length * 2.0;
              component['PhysicsComponent'] = newComponent;
            }
          });

        continue;
      }
      case 'v0.0.4': {
        lvl.version = 'v0.0.5';
        const fileMap: any = {};
        const animations: any = {};
        const nameMap: any = {};
        const collectionMap: any = {};
        for (const animation of Object.values(lvl.scene.animations) as any[]) {
          // Animations might have the same name between files. The only unique key possible is (file, index).
          const key = animation.source + '.' + animation.index;
          nameMap[key] = animation.id;
          fileMap[animation.source] = true;
        }

        for (const animationSource of Object.keys(fileMap) as any[]) {
          // actual animation file
          if (!animationSource) continue;

          const source = await window.fs.readResource(animationSource);

          if (!source) continue;
          const gltf = JSON.parse(source);

          const collection_id = uuid.v4();
          const definitions = [];

          for (const [index, config] of gltf.animations.entries()) {
            let id = uuid.v4();

            const key = animationSource + '.' + index;
            if (nameMap[key]) {
              id = nameMap[key];
              collectionMap[id] = collection_id;
            }
            const definition = {
              id,
              collection_id,
              name: config.name,
              loops: true,
            };
            definitions.push(definition);
          }

          animations[collection_id] = {
            id: collection_id,
            source: animationSource,
            definitions,
          };
        }

        // NOTE: I'm not exactly happy with this duplication of data,
        // but it does the job for now. We should look to
        // remove it in the future.
        for (const machine of Object.values(lvl.scene.animation_state) as any[]) {
          const badNodeIds: any = {};
          const nodes = [];
          const edges = [];
          for (const node of machine.nodes) {
            const animation_id = node.animation_id;
            const collection_id = collectionMap[animation_id];
            if (!collection_id) {
              badNodeIds[node.id] = true;
              continue;
            }
            nodes.push({
              ...node,
              animation_id: [animation_id, collection_id],
            });
          }

          for (const edge of machine.edges) {
            if (badNodeIds[edge.to] || badNodeIds[edge.from]) {
              continue;
            }
            edges.push({ ...edge });
            // This edge has invalid data. Delete.
          }

          lvl.scene.animation_state[machine.id].nodes = nodes;
          lvl.scene.animation_state[machine.id].edges = edges;
        }

        lvl.scene.animations = animations;
        continue;
      }
      case 'v0.0.3': {
        // We implemented proper devicePixelRatio scaling for the viewport.
        // Because we were supersampling everything by 2x, this means that all text and sprites need to be scaled down by 2.
        lvl.version = 'v0.0.4';
        Object.values<Prefab>(lvl.scene.prefabs)
          .filter((prefab) => {
            return Object.values(prefab.components).some((component) => {
              return component['SpriteComponent'] || component['TextComponent'];
            });
          })
          .forEach((prefab: any) => {
            prefab.transform.translation = [
              prefab.transform.translation[0] / 2.0,
              prefab.transform.translation[1] / 2.0,
              prefab.transform.translation[2],
            ];

            const textComponent = prefab.components.find((component: any) => component['TextComponent']);
            if (textComponent) {
              textComponent['TextComponent'].font_size = textComponent['TextComponent'].font_size / 2.0;
            }

            const spriteComponent = prefab.components.find((component: any) => component['SpriteComponent']);
            if (spriteComponent) {
              prefab.transform.scale = [
                prefab.transform.scale[0] / 2.0,
                prefab.transform.scale[1] / 2.0,
                prefab.transform.scale[2] / 2.0,
              ];
            }
          });
        continue;
      }
      case 'v0.0.2': {
        // We reworked the `AudioListenerComponent` to have an `active` property.
        lvl.version = 'v0.0.3';
        Object.values<Prefab>(lvl.scene.prefabs)
          .flatMap((prefab) => prefab.components)
          .filter((component) => component['AudioListenerComponent'])
          .forEach((component) => {
            const oldListener = component['AudioListenerComponent'];
            component['AudioListenerComponent'] = {
              id: oldListener.id,
              active: true,
            };
          });
        continue;
      }
      case 'v0.0.1': {
        // We reworked the animation state machines to have a `loops` parameter.
        lvl.version = 'v0.0.2';
        Object.values<AnimationStateMachine>(lvl.scene.animation_state || {})
          .flatMap((machine) => machine.nodes)
          .forEach((node) => (node.loops = true));
        continue;
      }
      default: {
        // We reworked the animation state machines to have a `loops` parameter.
        lvl.version = 'v0.0.2';
        Object.values<AnimationStateMachine>(lvl.scene.animation_state || {})
          .flatMap((machine) => machine.nodes)
          .forEach((node) => (node.loops = true));
        continue;
      }
    }
  }

  return lvl;
}

export async function migrateVfx(vfx: Particle): Promise<Particle> {
  while (vfx.version !== VFX_VERSION) {
    switch (vfx.version) {
      case 'v0.0.0': {
        // Add `tint` parameter to ColorChannel::Image
        for (const action of vfx.actions) {
          const particle = action.appearance.particle_type;

          if ('Quad' in particle && 'Image' in particle.Quad.channel1) {
            particle.Quad.channel1.Image.tint = [1.0, 1.0, 1.0];
          }

          if ('Quad' in particle && 'Image' in particle.Quad.channel2) {
            particle.Quad.channel2.Image.tint = [1.0, 1.0, 1.0];
          }
        }

        vfx.version = 'v0.0.1';
        continue;
      }
      case undefined: {
        vfx.version = 'v0.0.0';
        continue;
      }
    }
  }

  return vfx;
}
